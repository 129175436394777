import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'api/config/queryClient';
import { VisitModel } from 'api/services/visits/models';
import axios from 'axios';
import useEventListener from 'lib/events/useEventListener';
import { API_URL } from 'redux/actions/types';
const queryKey = "GetVisits";

const fetchVisits = async (start, end, communities) => {
  console.log(start, end)
  const { data } = await axios.get(`${API_URL}/visits/`, {
    params: { start, end, communities: communities }
  });
  return data;
};

export const useVisits = (start, end, communities) => {
  const queryResult = useQuery({
    enabled: true,
    queryKey: [queryKey, start, end],
    queryFn: () => fetchVisits(start, end, communities),
  });

  // Escucha el evento "visitUpdated" para invalidar las queries relacionadas
  useEventListener<VisitModel>(["visitUpdated"], () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === queryKey,
    });
  });

  return queryResult;
};
