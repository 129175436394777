import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SuiButton from 'components/SuiButton';
import SuiBox from 'components/SuiBox';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Tooltip } from '@mui/material';
import { AiOutlineEye } from 'react-icons/ai';
import SuiBadge from 'components/SuiBadge';
import SuiDatePicker from 'components/SuiDatePicker';
import SuiTypography from 'components/SuiTypography';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
// Soft UI Dashboard PRO React components
import SuiSelect from 'components/SuiSelect';
import Checkbox from '@mui/material/Checkbox';
// Settings page components
import { getTicketsTable } from 'redux/actions/tickets';
import { getCommunitiesByUser } from 'redux/actions/communities';
import { postExportVisitReports } from 'redux/actions/reports';
import { getPermission } from 'utils';
import { getUsersByManagement } from 'redux/actions/users';
import { getAuthUserProfile } from 'stores/authentication/helpers';
import { useGetCommunities } from 'api/services/communities/queries/GetCommunities';

export default function ExportReports(props) {
  const [priority, setpriority] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const exportFileResponse = useSelector((state) => state.reports.postExportVisitReports);
  const profile = getAuthUserProfile();
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [states, setStates] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [isAfterSale, setIsAfterSale] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState([]);
  const [members, setMembers] = useState([]);
  const getCommunitesResponse = useGetCommunities();
  const usersManagementResponse = useSelector((state) => state.users.getUsersByManagement);
  const [member, setMember] = useState(null);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [format, setFormat] = useState('pdf');
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (getPermission(['superadmin', 'administrador'])) {
      if (!props.member) {
        let profile = getAuthUserProfile();
        dispatch(getUsersByManagement(profile.management.slug));
      }
    }
  }, []);
  const handleSubmit = (e) => {
    let form = {
      communities: slug ? [slug] : community.map((c) => c.value),
      management_slug: profile.management.slug,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      type: format,
    };
    if (member) {
      form.authors = [member];
    }
    dispatch(postExportVisitReports(form));
    setDisabled(true);
  };

  useEffect(() => {
    if (exportFileResponse.data) {
      setDisabled(false);
      setState({ ...state, right: false });
    }
  }, [exportFileResponse]);

  useEffect(() => {
    if (!slug) {
    }
  }, [slug]);

  useEffect(() => {
    if (usersManagementResponse.data) {
      setMembers(usersManagementResponse.data);
    }
  }, [usersManagementResponse]);

  useEffect(() => {
    if (getCommunitesResponse.data && communities.length === 0) {
      setCommunities(
        getCommunitesResponse.data.map((item) => {
          return { value: item.slug, label: item.name };
        })
      );
    }
  }, [getCommunitesResponse]);

  const list = () => {
    return (
      <Box role="presentation">
        <SuiBox height={'100%'}>
          <Card style={{ marginBottom: 20, overflow: 'visible', width: '100%', height: '100%' }}>
            <Grid container alignItems={'center'}>
              <SuiTypography component="h6" fontWeight="bold" p={3}>
                Exportar
              </SuiTypography>
              <Grid item xs={12}>
                <SuiBox component="form" pb={3} px={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <SuiTypography variant="button">Formato</SuiTypography>
                      <Grid sx={{ marginTop: 0 }} container>
                        <Grid item xs={4} sm={3}>
                          <SuiTypography variant="button">PDF</SuiTypography>
                          <Checkbox
                            ml={2}
                            checked={format === 'pdf'}
                            onChange={(e) => setFormat('pdf')}
                          />
                        </Grid>
                        <Grid item xs={4} sm={3}>
                          <SuiTypography variant="button">Excel</SuiTypography>
                          <Checkbox
                            ml={2}
                            checked={format === 'excel'}
                            onChange={(e) => setFormat('excel')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SuiTypography component="label" variant="caption" fontWeight="bold">
                        Fecha de inicio
                      </SuiTypography>
                      <SuiDatePicker
                        style={{ zIndex: 1000000 }}
                        onChange={(e) => setSelectedStartDate(e[0].toISOString().split('T')[0])}
                      />
                      <SuiTypography variant="caption">
                        *Al tener fecha de inicio, solamente se exportarán visitas <br /> que fueron
                        creadas después de la fecha definida.
                      </SuiTypography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SuiTypography variant="button">Fecha de fin</SuiTypography>
                      <SuiDatePicker    
                        style={{ zIndex: 1000000 }}
                        onChange={(e) => setSelectedEndDate(e[0].toISOString().split('T')[0])}
                      />
                    </Grid>
                    {getPermission(['superadmin', 'administrador']) && (
                      <Grid item xs={12}>
                        <SuiTypography component="label" variant="caption" fontWeight="bold">
                          Colaborador
                        </SuiTypography>
                        <SuiSelect
                          size="small"
                          value={member}
                          placeholder="Colaborador"
                          style={{ zIndex: 1000, width: '100%' }}
                          options={[
                            { value: '', label: 'Todos' },
                            ...members.map((member) => {
                              return { value: member.email, label: member.first_name };
                            }),
                          ]}
                          onChange={(e) => setMember(e.value)}
                        />
                      </Grid>
                    )}

                    {!slug ? (
                      <Grid item xs={12} sm={12} pt={0}>
                        <SuiBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                          <SuiTypography component="label" variant="caption" fontWeight="bold">
                            Comunidades
                          </SuiTypography>
                        </SuiBox>
                        <SuiSelect
                          size="small"
                          onChange={(e) => {
                            setCommunity(e);
                          }}
                          placeholder="Todas"
                          isMulti
                          options={communities}
                          value={community}
                        />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <SuiBox p={3} style={{ textAlign: 'center', width: '100%' }}>
                      <SuiButton
                        variant="gradient"
                        color="success"
                        onClick={handleSubmit}
                        disabled={disabled}
                      >
                        {disabled ? <CircularProgress size={15} color="light" /> : 'Exportar'}
                      </SuiButton>
                    </SuiBox>
                  </Grid>
                </SuiBox>
              </Grid>
            </Grid>
          </Card>
        </SuiBox>
      </Box>
    );
  };

  return (
    <div style={{ padding: 10 }}>
      <Tooltip key={props.uuid} title={'Exportar'} placement="top">
        <SuiTypography
          variant="button"
          style={{ cursor: 'pointer', float: 'right' }}
          onClick={toggleDrawer('right', true)}
          bgcolor={'info'}
        >
          Exportar
        </SuiTypography>
      </Tooltip>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        sx={{ zIndex: 99999 }}
      >
        {list('right')}
      </Drawer>
    </div>
  );
}
