import { useState, FC } from "react";
import Divider from "@mui/material/Divider";
import { SuiButtonTs } from "components/SuiButton";
import { SuiTypographyTs } from "components/SuiTypography";
import { UUID } from "crypto";
import { isPermissionEnabled } from "utils";

import TicketsTable from "components/Tickets/TicketsTable";
import { Box } from "@mui/material";
import CreateTicketModal from "components/Tickets/AddTicketModal/buttonAlt";
import ExportAlt from "../Export/alt";
import { TicketsTableViewDisplay } from "components/Tickets/TicketsTable/table.helper";

interface TicketsManagementProps {
  reporting?: unknown;
  communitySlug?: string;
  providerUUID?: UUID;
  columnsView?: TicketsTableViewDisplay;
}

const TicketsManagement: FC<TicketsManagementProps> = ({
  reporting,
  communitySlug,
  providerUUID,
  columnsView = TicketsTableViewDisplay.detailed,
}) => {
  const [queryCompletedTickets, setQueryCompletedTickets] = useState(false);
  const [queryIsAfterSale, setQueryIsAfterSale] = useState(false);
  return (
    <Box p={2}>
      <SuiTypographyTs variant="h4" fontWeight="medium" textAlign="center">
        Tareas
      </SuiTypographyTs>
      <Divider />

      {!reporting && (
        <Box display={"flex"} justifyContent={"end"} mb={1}>
          <ExportAlt />
          <SuiButtonTs
            variant="text"
            onClick={() => setQueryCompletedTickets((prev) => !prev)}
          >
            <SuiTypographyTs variant="button">
              {!queryCompletedTickets
                ? "Ver finalizadas"
                : "Ocultar finalizadas"}
            </SuiTypographyTs>
          </SuiButtonTs>
          <SuiButtonTs
            variant="text"
            onClick={() => setQueryIsAfterSale((prev) => !prev)}
          >
            <SuiTypographyTs variant="button">
              {!queryIsAfterSale
                ? "Ver tareas de postventa"
                : "Ocultar tareas de postventa"}
            </SuiTypographyTs>
          </SuiButtonTs>

          {!providerUUID && isPermissionEnabled("tasks-createTask") && (
            <CreateTicketModal />
          )}
        </Box>
      )}

      <Box>
        <TicketsTable
          columnsView={columnsView}
          params={{
            is_finished: queryCompletedTickets,
            is_after_sale: queryIsAfterSale,
            ...(communitySlug && { communities: communitySlug }),
            ...(providerUUID && { service_provider: providerUUID }),
          }}
        />
      </Box>
    </Box>
  );
};

export default TicketsManagement;
